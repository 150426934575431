import React from "react"
import { PortableText } from '@portabletext/react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Link from '@mui/material/Link'
import { useTheme } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';
import { PortableTextInlineLink } from "../utils/portableInlineLink"
import Image from "gatsby-plugin-sanity-image"
import { getGatsbyImageData } from "gatsby-source-sanity"


export const RenderPortableText = (props) => {

  const { setAsHeading = false, previewData, sanityConfig, value, textAlign, component } = props

  const theme = useTheme()

  const block = {
    normal: ({ children }) => <Typography sx={{ py: 2, color: 'inherit' }} variant={setAsHeading ? setAsHeading : 'body1'}>{children}</Typography>,
    body2: ({ children }) => <Typography sx={{ textAlign: textAlign, py: 2, color: 'inherit' }} variant='body2'>{children}</Typography>,
    caption: ({ children }) => <Typography sx={{ textAlign: textAlign, py: 2, color: 'inherit' }} variant='caption'>{children}</Typography>,
    h1: ({ children }) => {
      return <Typography sx={{ textAlign: textAlign, py: { xs: 5, md: 0 }, color: 'inherit' }} variant="h1" component="div">{children}</Typography>
    },
    h2: ({ children }) => {
      return <Typography sx={{ textAlign: textAlign, py: { xs: 5 }, color: 'inherit' }} variant="h2">{children}</Typography>
    },
    h3: ({ children }) => <Typography sx={{ py: { xs: 5 }, color: 'inherit' }} variant="h3">{children}</Typography>,
    h4: ({ children }) => <Typography sx={{ py: { xs: 7 }, color: 'inherit' }} variant="h4">{children}</Typography>,
    h5: ({ children }) => <Typography sx={{ py: { xs: 5 }, color: 'inherit' }} variant="h5">{children}</Typography>,
    h6: ({ children }) => {
      return <Typography sx={{ py: { xs: 5 }, color: 'inherit' }} variant="h6">{children}</Typography>
    },
    blockquote: ({ children }) => {
      return (
        <Box sx={{ py: { xs: 5 }, mx: 0 }} component="figure">
          <Typography sx={{ fontStyle: 'italic', py: { xs: 0 }, color: 'primary.main', pl: 3 }} variant="h3" component="blockquote">
            {children}
          </Typography>
          {value.cite && <Typography align="center" variant="subtitle1" component="figcaption">{value.cite}</Typography>}
        </Box>
      )
    },
  };

  const marks = { 
    em: ({ children }) => <Typography sx={{ color: value.value, fontStyle: 'italic' }} variant="body1" component="span">{children}</Typography>,
    strong: ({ children }) => { return (<Box component="span" sx={{ fontWeight: 900
     }}>{children}</Box>)},

    link: ({ children, value }) => {

      return (
        <PortableTextInlineLink color={value.value} value={value}>{children}</PortableTextInlineLink>
      )
    },
    internalLink: ({ children, value }) => {

      return (
        <PortableTextInlineLink color={value.value} value={value}>{children}</PortableTextInlineLink>
      )
    },
    underline: ({ children }) => <Typography variant="body1" className="underline" component="span">{children}</Typography>,
    // Color
    textColor: ({ children, value }) => <span style={{ color: value.value }}>{children}</span>,
    highlightColor: ({ children, value }) => (
      <span style={{ background: value?.value }}>{children}</span>
    ),
    file: ({ children, value }) => <Link target="_blank" rel="noopener" href={value?.asset?.url} style={{ color: value?.value }}>{children}</Link>,
  };

  const standardPortableText = {
    types: {
      // ! Old Image block for deprecation 
      image: ({ value }) => {

        return (
          <Box sx={{ py: 6, }}>
            <Image
              // pass asset, hotspot, and crop fields
              asset={
                getGatsbyImageData(
                  previewData &&
                  previewData?.image?.asset,
                  { maxWidth: 100 },
                  sanityConfig,
                ) || value?.asset
              }
              // tell Sanity how large to make the image (does not set any CSS)
              // width={1300}
              // style it how you want it
              style={{
                objectFit: "cover",
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Typography sx={{ pl: 1, borderLeft: `1px solid ${theme.palette.tertiary.main}` }} variant='body2'>{value?.asset?.description}</Typography>
          </Box>
        )
      },
      // New Image block
      imageOptions: ({ value }) => {

        return (
          <Box sx={{ py: 6, }}>
            <Image
              // pass asset, hotspot, and crop fields
              asset={
                getGatsbyImageData(
                  previewData &&
                  previewData?.image?.asset,
                  { maxWidth: 100 },
                  sanityConfig,
                ) || value?.image?.asset
              }
              // tell Sanity how large to make the image (does not set any CSS)
              // width={1300}
              // style it how you want it
              style={{
                objectFit: "cover",
                maxWidth: '100%',
                height: 'auto',
                mixBlendMode: value?.mixBlendMode && value?.mixBlendMode,
              }}
            />
            <Typography sx={{ pl: 1, borderLeft: `1px solid ${theme.palette.tertiary.main}` }} variant='body2'>{value?.asset?.description}</Typography>
          </Box>
        )
      },
      callToAction: ({ value, isInline }) =>
        isInline ? (
          <a style={{ color: 'blue' }} href={value?.url}>{value?.text}</a>
        ) : (
          <div style={{ color: 'blue' }} className="callToAction">{value?.text}</div>
        ),
      blockquote: ({ value }) => {
        return (
          <Box sx={{ mx: { xs: 0, md: -9 } }} component="figure">

            <Typography sx={{ py: { xs: 6, md: 6 }, color: value?.markDefs[0].value, pl: 1, borderLeft: `1px solid ${theme.palette.highlight.main}` }} align="center" variant="h2" component="blockquote">
              {value?.text}
            </Typography>
            {value?.cite && <Typography align="center" variant="subtitle1" component="figcaption">{value?.cite}</Typography>}
          </Box>
        )
      },
    },
    list: ({ value, children, }) =>
    // console.log("list", props) ||
    (value.listItem === "bullet" ? (
      <List sx={{ listStyle: 'inside', pt: { xs: 0 }, mt: { xs: '-4px' } }} component="ul" dense={true}>{children}</List>
    ) : (
      <List sx={{ listStyle: 'decimal inside', pt: { xs: 0 }, mt: { xs: '-4px' } }} component="ol" dense={true}>{children}</List>
    )),
    listItem: ({ value, children }) => {
      
      return (
        (value.listItem === "bullet" ? (
          <ListItem sx={{ alignItems: "flex-start",  color: 'inherit', pl: 0 }}>
            <ListItemIcon sx={{ mt: 2, minWidth: 16 }}>
              <CircleIcon color='red' sx={{ width: 4, height: 4 }} />
            </ListItemIcon>
              <Box sx={{display: 'block'
              }}>{children}</Box>
              
          </ListItem>
        ) : (
          <ListItem sx={{ color: 'inherit', display: 'list-item', px: 0 }}>
            <ListItemIcon sx={{ minWidth: 16 }}>
            </ListItemIcon>
              <Box sx={{display: 'block'
              }}>{children}</Box>
              
          </ListItem>
        ))
      )
    },
    marks: marks,
    block: block,

  }

  return (
    <Box className={``}>
      <PortableText value={value || []} components={standardPortableText} />
    </Box>
  )

}

// components={standardPortableText}


//! [@portabletext/react] Unknown block type "block", specify a component for it in the `components.types` prop